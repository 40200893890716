import React from "react";
import PropTypes from "prop-types";
import { mediaQueryHOC } from "../../adapters/helpers/Hooks";
import Block from "../../adapters/helpers/Block";

function StartGuide(props) {
  const { document, isMedium, onClickCallback } = props;
  const block = new Block(props);
  let extraAttributes = props.extraAttributes || {};
  const title = block.getFieldValue("Name");
  return (
    <div className="start-guide">
      {block.renderChildBlocks(
        { extraAttributes: extraAttributes },
        onClickCallback,
        isMedium
      )}
    </div>
  );
}

export default mediaQueryHOC(StartGuide);

StartGuide.propTypes = {
  className: PropTypes.string,
  extraAttributes: PropTypes.any,
  isMedium: PropTypes.any,
  customEvent: PropTypes.any,
  onClickCallback: PropTypes.func,
};
