import { SpotlightWaitlistConstants } from '../../Mixture/View/exportComponent';
const mixtureParticipationAPI = process.env.MIXTURE_CHAMPAIGN_PARTICIPATION;
const mixtureChannelId = process.env.MIXTURE_CHANNEL_ID;
const mixtureCampaignIdIO2 = process.env.MIXTURE_CHAMPAIGN_ID_IO2;
const mixtureLocale = process.env.MIXTURE_CAMPAIGN_LOCALE;
const mixtureTraitIdValue = process.env.MIXTURE_TRAIT_ID_VALUE;
const mixtureTraitSource = process.env.MIXTURE_TRAIT_SOURCE;

const createMixtureBody = (email) => {
  const body = {};

  body.campaign = {
    "campaignId": Number(mixtureCampaignIdIO2),
    "locale": mixtureLocale,
  };

  body.consumer = {
    "email": email,
  };

  body.traits = [
    {
      "traitId": mixtureTraitSource,
      "value": [SpotlightWaitlistConstants.interstitalSourceIO2],
    },
  ];

  return body;
};

export const mixtureFormRegisterIO2 = async ({ email }) => {
  try {
    let body = createMixtureBody(email);
    const response = await fetch(mixtureParticipationAPI, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'channelId': mixtureChannelId,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      return response;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
